import { IonButton, IonCheckbox, IonItem, IonLabel } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import MembersService from "../../line/services/members.service";
import { ScrollpageWithHeader } from "../../line/screens/scrollpage-with-header/scrollpage-with-header";
import { TextField, TextFieldRounded } from "../../line/components/text-field/text-field";
import AuthService from "../../line/services/auth.service";
import liff from "@line/liff";
import { NewUpdatedTerms } from "../../line/screens/account-screens/account-terms/account-terms";
import { connect } from "react-redux";
import { StepHeader1 } from "./step-headers/step-headers";
import { useAtom } from "jotai";
import { Atom_Registration_Email, Atom_Registration_Email_Confirm, Atom_Registration_Holder, Atom_Registration_Selected_Retailer } from "../../line/state/atoms/registration.email.atom";
import {setLIFF_ID} from "../../redux/global/global.actions";

let iyId = process.env.REACT_APP_IY_ID as string;
let ybId = process.env.REACT_APP_YB_ID as string;
let waonId = process.env.REACT_APP_WAON_ID as string;
let izumiId = process.env.REACT_APP_IZUMI_ID as string;

function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

declare global {
  interface Window {
    dataLayer: Array<Object>;
  }
}
function useQueryParams() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const mapStateToProps = (state: any) => {
  return {
    isSocialAuthenticated: state.global.isSocialAuthenticated,
    LIFF_ID: state.global.LIFF_ID
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {

    setLiffId: (LIFF_ID: string) => dispatch(setLIFF_ID(LIFF_ID))
  };
};

interface Props {
  isSocialAuthenticated: any;
  LIFF_ID: any;
  setLiffId: Function;
}

export const RegistrationPageComponent = (props: Props) => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "virtualPageview",
      pageTitle: "T&CPage",
      pageUrl: window.location.pathname, // replace page url with current url
      dm_screen_type: "other", // if list of coupons displayed then "list_view" , if one coupon displayed as product then "product_view" otherwise set to "other"})
    });
  }, []);

  let queryParams = useQueryParams();
  // let LIFF_ID = process.env.REACT_APP_LIFF_ID as string
  const history = useHistory();
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useAtom(Atom_Registration_Email);
  const [holderNumberForRegistration] = useAtom(Atom_Registration_Holder);
  const [selectedRetailerForRegistration] = useAtom(Atom_Registration_Selected_Retailer);
  const [confirmEmail, setConfirmEmail] = useAtom(Atom_Registration_Email_Confirm);
  
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [openErrorModel, setOpenErrorModel] = useState<boolean>(false);
  let retailerId = localStorage.getItem('app_retailer_id')
  const getLiffId = () => {
    let LIFF_ID = process.env.REACT_APP_LIFF_ID_ALL as string
    if (queryParams.get('campaign')) {
      if (!queryParams.get('retailerId')) {
        // console.log(queryParams.get('retailers'))
        LIFF_ID = process.env.REACT_APP_LIFF_ID_ALL_CAMPAIGN as string
        // localStorage.setItem('app_retailer_id', queryParams.get('retailerId'))
      } else if (queryParams.get('retailerId') === iyId) {
        LIFF_ID = process.env.REACT_APP_LIFF_ID_IY_CAMPAIGN as string
      } else if (queryParams.get('retailerId') === ybId) {
        LIFF_ID = process.env.REACT_APP_LIFF_ID_YB_CAMPAIGN as string
      }
    } else {
      if (!queryParams.get('retailerId')) {
        // console.log(queryParams.get('retailers'))
        LIFF_ID = process.env.REACT_APP_LIFF_ID_ALL as string
        // localStorage.setItem('app_retailer_id', queryParams.get('retailerId'))
      } else if (queryParams.get('retailerId') === iyId) {
        LIFF_ID = process.env.REACT_APP_LIFF_ID_IY as string
      } else if (queryParams.get('retailerId') === ybId) {
        LIFF_ID = process.env.REACT_APP_LIFF_ID_YB as string
      } else if (queryParams.get('retailerId') === izumiId) {
        LIFF_ID = process.env.REACT_APP_LIFF_ID_YouMe as string
      } else if (queryParams.get('retailerId') === waonId) {
        LIFF_ID = process.env.REACT_APP_LIFF_ID_WAON as string
      }
    }
    return LIFF_ID;
  }
  useEffect(() => {
    setDefaultEmail();
    return () => { };
  }, []);

    const setDefaultEmail = async () => {
    let LIFF_ID = props.LIFF_ID;
    if (!props.LIFF_ID) {
      LIFF_ID = getLiffId();
      props.setLiffId(LIFF_ID);
    }
    liff
      .init({ liffId: LIFF_ID })
      .then(async () => {
        console.log(liff.isLoggedIn());
        console.log(await liff.getDecodedIDToken())
        if (liff.isLoggedIn()) {
          console.log(
            (await liff.getDecodedIDToken())
              ? (await liff.getDecodedIDToken()).email
              : ""
          );
          setEmail(
            (await liff.getDecodedIDToken())
              ? (await liff.getDecodedIDToken()).email
              : ""
          );
          setConfirmEmail(
            (await liff.getDecodedIDToken())
              ? (await liff.getDecodedIDToken()).email
              : ""
          );
        } else {
          liff.login();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSignup = async () => {
    setLoading(true);
    if(loading) return;
    const userId = (await liff.getProfile()).userId;
    let cardNumberValueText = holderNumberForRegistration ? holderNumberForRegistration.replaceAll(" ", "") : "";

    setTimeout(() => {
    }, 5000)
    AuthService.signupSocialWithHolder(email, cardNumberValueText, selectedRetailerForRegistration.id, {
      type: 1,
      id: userId,
    })
      .then(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "virtualPageview",
          pageTitle: "sign-up",
          pageUrl: window.location.pathname, // replace page url with current url
          dm_screen_type: "other", // if list of coupons displayed then "list_view" , if one coupon displayed as product then "product_view" otherwise set to "other"})
        });

        AuthService.socialLogin(liff.getAccessToken(), userId)
          .then((response) => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "virtualPageview",
              pageTitle: "login",
              pageUrl: window.location.pathname, // replace page url with current url
              dm_screen_type: "other", // if list of coupons displayed then "list_view" , if one coupon displayed as product then "product_view" otherwise set to "other"})
            });

            localStorage.setItem("user-data", JSON.stringify(response.data));
            MembersService.patchPaperless(selectedRetailerForRegistration, true)
            return MembersService.getFavoriteStores();
          })
          .then((response) => {
            setLoading(false);
            history.push(`/sign-up/retailer/${selectedRetailerForRegistration.id}/prefecture`);
          })
          .catch((err) => {
            setLoading(false);
            setErrorMessage(err.response.data.error);
            setOpenErrorModel(true);
          });
      })
      .catch((err) => {
        setLoading(false);
        setErrorMessage(err.response.data.error);
        setOpenErrorModel(true);
      });
  };

  return (
    <ScrollpageWithHeader title="メルアドレスを登録" id="terms">
      <div className="mt-6">

        <StepHeader1 />

        {openErrorModel && (
          <div className="text-red-600 text-xs mt-3 mb-3 pl-6">{errorMessage}</div>
        )}

        <div className="p-6">

          <div className="account-editing text-sm font-bold">メールアドレス</div>

          {/* Email address */}
          <TextFieldRounded
            placeholder="メールアドレスを入力してください"
            value={email}
            onChange={(value: any) => setEmail(value)}
            type="email"
            className="mt-6"
          />

          {/* Email field error - show if error is present */}
          {email && !validateEmail(email) && (
            <div className="text-red-600 text-xs mt-0.5 mb-5">
              メールアドレスを正しく入力してください。
            </div>
          )}

          {/* Email address verification */}
          <TextFieldRounded
            placeholder="メールアドレス確認用"
            value={confirmEmail}
            onChange={(value: any) => setConfirmEmail(value)}
            type="email"
            className="mt-3"
          />

          {confirmEmail && !validateEmail(confirmEmail) && (
            <div className="text-red-600 text-xs mt-0.5 mb-5">
              確認用のメールアドレスを入力してください。
            </div>
          )}

          {email &&
            confirmEmail &&
            validateEmail(confirmEmail) &&
            email !== confirmEmail && (
              <div className="text-red-600 text-xs mt-0.5 mb-5">
                確認用のメールアドレスが一致していません。
              </div>
            )}

          <div className="account-editing text-sm font-bold mt-6">カードを登録</div>

          <div className="flex space-x-4 mt-6">
            <div className="flex-1">  
              {/* Card number */}
              <div onClick={() => {
                history.push("/sign-up/retailer");
              }}>
                <TextFieldRounded
                  placeholder="カードを登録してください"
                  readOnly={true}
                  value={holderNumberForRegistration}
                  onChange={(value: any) => { }}
                  type="email"
                />
              </div>
            </div>  
            {selectedRetailerForRegistration.image_url && <img src={selectedRetailerForRegistration.image_url} className="h-12 rounded-md" />}
          </div>


          <div className="text-sm bg-gray-100 text-black flex flex-col overflow-auto h-52 mt-6 p-4 rounded-md">
            <div className="font-bold text-sm">利用規約</div>
            <NewUpdatedTerms />
          </div>

          <div>
            <div className="my-6 flex items-center">
              <IonCheckbox
                mode="md"
                disabled={false}
                checked={checked}
                onIonChange={(e) => setChecked(e.detail.checked)}
              />
              <IonLabel className="ml-2" style={{ fontSize: "0.875rem" }}>利用規約に同意する</IonLabel>
            </div>
          </div>


        </div>

        <div className="mt-24 border-t px-4 py-3">
          <IonButton
            className="mx-0 h-12 mb-6 rounded text-875 disabled-DFDFDF rounded-25-rem"
            style={{ minHeight: 48 }}
            disabled={
              loading ||
              !email ||
              !confirmEmail ||
              !validateEmail(email) ||
              !validateEmail(confirmEmail) ||
              email !== confirmEmail ||
              !holderNumberForRegistration ||
              !selectedRetailerForRegistration.name ||
              !checked
            }
            onClick={handleSignup}
            expand="block"
            color="primary"
          >
            会員登録をしてお店の登録に進む
          </IonButton>
        </div>
      </div>

    </ScrollpageWithHeader>
  );
};

export const RegistrationPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationPageComponent);

