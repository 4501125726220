import {
  IonAlert,
  IonButton,
  useIonToast,
  useIonViewDidEnter,

} from "@ionic/react";
import { useEffect, useState } from "react";
import MembersService from "../../services/members.service";
import { useHistory } from "react-router-dom";
import { ScrollpageWithHeader } from "../scrollpage-with-header/scrollpage-with-header";
import { connect } from "react-redux";
import {setAccountMode, setHolderCards} from "../../../redux/holder-cards/holder-cards.actions";
import { fetchCategories, fetchOffers } from "../../../redux/offer-list/offer-list.actions";
import { setSelectedTab } from "../../../redux/tabs/tabs.actions";
import waon from "../screen2/1.png";
import izumi from "../screen2/2.png";
import yb from "../screen2/6.png";
import iy from "../screen2/10.png";
import app from "../../../App";

const mapStateToProps = (state: any) => {
  return {
    state: state,
    cards: state.cards.holders,
    accountScreenMode: state.cards.accountScreenMode,
    selectedStore: state.stores.selectedStore,
    retailer: state.retailers.selectedForAccount,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setAccountMode: (data: any) => dispatch(setAccountMode(data)),
    setHolderCards: (data: any) => dispatch(setHolderCards(data)),
    fetchOffers: () => dispatch(fetchOffers()),
    fetchCategories: () => dispatch(fetchCategories()),
    setSelectedTab: (data: any) => dispatch(setSelectedTab(data)),
  };
};

interface Props {
  cards: any[];
  state: any;
  setHolderCards: Function;
  retailer: any;
  accountScreenMode: any;
  fetchOffers: Function;
  fetchCategories: Function;
  setSelectedTab: Function;
  setAccountMode: Function;
  selectedStore?: any;
}

export const Screen9Component = (props: Props) => {
  let waonId = process.env.REACT_APP_WAON_ID as string;
  let izumiId = process.env.REACT_APP_IZUMI_ID as string;
  let iyId = process.env.REACT_APP_IY_ID as string;
  let ybId = process.env.REACT_APP_YB_ID as string;

  let imageURL: any = {};
  imageURL[waonId] = waon;
  imageURL[izumiId] = izumi;
  imageURL[iyId] = iy;
  imageURL[ybId] = yb;

  const history = useHistory();
  const [present, dismiss] = useIonToast();
  let [loading,setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showDelete, setShowDelete] = useState(false);
  const [isCardDeleting, setCardDeleting] = useState(false);
  const [openErrorModel, setOpenErrorModel] = useState<boolean>(false);
  let selectedRetailer = props.accountScreenMode ? props.retailer : props.selectedStore ? props.selectedStore.retailer : props.retailer;
  let selectedStore = props.selectedStore ? props.selectedStore : { retailer_id: "-1" };
  let app_retailer_id = localStorage.getItem("app_retailer_id");
  if(app_retailer_id){
    if(!selectedRetailer){
      selectedRetailer = {
        id: Number(app_retailer_id)
      }
    }
    if(!props.selectedStore){
      selectedStore = {
        retailer_id: Number(app_retailer_id)
      }
    }
  }
  if(!selectedRetailer){
    selectedRetailer = {
      id: -1
    }
  }

  let { retailer } = props;
  if (!retailer) {
    retailer = selectedStore.retailer;
  }
  if (!retailer) {
    retailer = {
      id: -1
    };
  }

  let holder = props.accountScreenMode ? props.cards.filter(
    (c) => {
      console.log(c);
      return c.retailer_id === parseInt(retailer.id)
    }
  )[0] : props.cards.filter(
    (c) => {
      console.log(c)
      return c.retailer_id === parseInt(selectedStore.retailer_id)
    }
  )[0]

  console.log("$$$$$$$$$$$$$", holder)
  if(selectedStore.retailer_id==-1){
    holder = props.cards[0]
  }
  // let holder = props.cards.filter(
  //     (c) => c.retailer_id === parseInt(props.selectedStore.retailer_id)
  // )[0]

  if (holder && retailer && retailer.id!=-1 && holder.retailer_id !== retailer.id) {
    holder = null
  }

  const formatCreditCardNumber = (value: any, retailerId: any) => {
    if (!value) {
      return value;
    }
    function clearNumber(value = "") {
      return value.replace(/\D+/g, "");
    }
    const clearValue = clearNumber(value);
    let nextValue;
    if (retailerId !== Number(izumiId)) {
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
    }
    else if (retailerId === Number(izumiId)) {
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        12
      )} ${clearValue.slice(12, 13)}`;
    } else {
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
    }
    return nextValue.trim();
  };
  const [cardNumber, setCardNumber] = useState(null);
  const triggerGTM = async (data: any) => {
    const eventProps = await data.data.state;
    let screenType = "";
      screenType = "Card View";
    window.dataLayer = window.dataLayer || [];
    // console.log({
    //   event: "virtualPageview",

    //   pageUrl: window.location.pathname, //fill dynamic uisng api value when page/state changes

    //   pageTitle: "CARD_REGISTERED", // fill dynamic uisng api value when page/state changes
    //   dm_fsp_id: data.data.card?data.data.card.replaceAll(" ", "") : "",
    //   dm_screen_type: screenType, //fill dynamic:'sign up' 'dm_screen_type': 'list_view' for list view screen, 'product_view' for products view screen

    //   userId: JSON.parse(localStorage.getItem("user-data")!).id, //fill dynamic uisng api value when page/state changes

    //   dm_retailer_id: eventProps.stores.selectedStore
    //       ? eventProps.stores.selectedStore.retailer.id
    //       : 0
    // })
    window.dataLayer.push({
      event: "virtualPageview",

      pageUrl: window.location.pathname, //fill dynamic uisng api value when page/state changes

      pageTitle: "CARD_REGISTERED", // fill dynamic uisng api value when page/state changes
      dm_fsp_id: data.data.card?data.data.card.replaceAll(" ", "") : "",
      dm_screen_type: screenType, //fill dynamic:'sign up' 'dm_screen_type': 'list_view' for list view screen, 'product_view' for products view screen

      userId: JSON.parse(localStorage.getItem("user-data")!).id, //fill dynamic uisng api value when page/state changes

      dm_retailer_id: eventProps.stores.selectedStore
          ? eventProps.stores.selectedStore.retailer.id
          : 0, //fill dynamic uisng api value when page/state changes
    //   6900175564369054
    });

  }
  const registerCard = () => {
    setLoading(true);
    let cardNumberValueText = cardNumber ? cardNumber.replaceAll(" ", "") : "";
    if (!cardNumberValueText && cardNumberValueText === "") {
      setErrorMessage("カード番号の入力が必要です。");
      setOpenErrorModel(true);
    } else {
      MembersService.registerCard(cardNumberValueText, retailer.id)
          .then(()=>{
            MembersService.patchPaperless(retailer, true)
          })
        .then(() => {
          props.setAccountMode(false);
          triggerGTM({
            eventName: "",
            data: {
              card: cardNumber,
              state: props.state
            },
          }).then(()=>{
            setCardNumber("");
            present("カードの登録に成功しました。", 2000);
            refetchHoldersAndNavigateToTabs();
            setLoading(false);
          })
          // 6900 1755 6443 6569
          // 6900 1755 6443 5055
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            setErrorMessage(error.response.data.error);
            setOpenErrorModel(true);
          }
          setLoading(false);
        });
    }
  };

  const refetchHoldersAndNavigateToTabs = () => {
    props.setAccountMode(false);
    getHolder();
    setCardNumber("");
    props.fetchOffers();
    props.fetchCategories();
    props.setSelectedTab('home');
    if(!props.selectedStore && !props.accountScreenMode && !isCardDeleting){
      setTimeout(() => {
        setCardNumber("");
        history.push("/4");
        setCardDeleting(false);
      }, 1000);
    } else {
      setTimeout(() => {
        setCardNumber("");
        history.push("/tabs/home");
        setCardDeleting(false);
        setLoading(false)
      }, 1000);
    }

  };

  useIonViewDidEnter(() => {
    getHolder();
    if (holder) {
      setCardNumber(holder.ref);
    } else {
      setCardNumber("");
    }
  }, [holder]);
  useEffect(() => {
    if (holder) {
      setCardNumber(holder.ref);
    } else {
      setCardNumber("");
    }
  }, [holder]);

  const cardDeletion = () => {
    MembersService.deleteCard(holder.id)
        .then(refetchHoldersAndNavigateToTabs)
        .then(() => {
          props.setHolderCards([]);
          setCardNumber("");
          cardNumberValue("");
          present("カードを正常に削除しました", 2000);
          setLoading(false)
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            setErrorMessage(error.response.data.error);
            setOpenErrorModel(true);
            setLoading(false);
          }
        });
  }
  const deleteCard = () => {
    console.log("Delete: ", holder);
    MembersService.patchPaperless(retailer, false).then(()=>{
      cardDeletion()
    }).catch((err)=>{
      console.log("Problem in setting paperless to false");
      cardDeletion()

    })


    // MembersService.deleteCard(holder.id)
    //   .then(refetchHoldersAndNavigateToTabs)
    //   .then(() => {
    //     props.setHolderCards([]);
    //     setCardNumber("");
    //     cardNumberValue("");
    //     present("カードを正常に削除しました", 2000);
    //   })
    //   .catch((error) => {
    //     if (error.response && error.response.data) {
    //       setErrorMessage(error.response.data.error);
    //       setOpenErrorModel(true);
    //     }
    //   });
  };

  const updateCard = () => {
    // console.log("update");
    setLoading(true);
    let cardNumberValueText = cardNumber ? cardNumber.replaceAll(" ", "") : "";
    if (!cardNumberValueText && cardNumberValueText === "") {
      setErrorMessage("カード番号の入力が必要です。");
      setOpenErrorModel(true);
    } else {
      MembersService.updateCard(
        holder.id,
        cardNumberValueText,
        retailer.id
      )
        .then(()=>{
          MembersService.patchPaperless(retailer, true).then(()=>{
            refetchHoldersAndNavigateToTabs();
          }).then(()=>{
            present("カード番号が正常に更新されました。", 2000);
            setLoading(false);
          })
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            setErrorMessage(error.response.data.error);
            setOpenErrorModel(true);
            setLoading(false);
          }
        });
    }
  };

  const getHolder = () => {
    MembersService.getHolders()
      .then((responseJson) => {
        props.setHolderCards(responseJson.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  useEffect(() => {
    getHolder();
  }, []);

  const handleInputFocus = (target: any) => {
    // this.setState({
    //   focused: target.name
    // });
  };

  const handleInputChange = (target: any) => {
    target.value = formatCreditCardNumber(target.value, retailer.id);
    // this.setState({ [target.name]: target.value });
    setCardNumber(target.value);
    setOpenErrorModel(false);
    setErrorMessage("");
  };

  let cardNumberValue:any = "";
  if(cardNumber){
    cardNumberValue = formatCreditCardNumber(cardNumber, retailer.id)
  }
 console.log(cardNumberValue)
  return (
    <ScrollpageWithHeader title="カードを登録" id="page-holders">
      <IonAlert
        isOpen={showDelete}
        onDidDismiss={() => setShowDelete(false)}
        cssClass="confirm-modal"
        header={"確認"}
        message={
          '<p class="text-gray-400">登録済みのカードを削除しますか？</p>'
        }
        buttons={[
          {
            text: "いいえ",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              setShowDelete(false);
              getHolder();
            },
          },
          {
            text: "はい",
            handler: () => {
              deleteCard();

              // console.log("Confirm Okay: ", holder);
            },
          },
        ]}
      />
      <div className="details flex flex-col justify-start overflow-auto pb-6 flex-1 px-5">
        {holder && selectedRetailer && holder.retailer_id === selectedRetailer.id && (
          <div className="flex items-center pt-4">
            <div className="flex items-center justify-center rounded-full">
              <img className="items-center justify-center rounded-md flex overflow-hidden object-contain"
                src={imageURL[holder.retailer.id]}
                alt="Card"
                style={{
                  height: "2.875rem",
                  width: "3.875rem",
                }}
              />
            </div>
            <div className="flex-1 pl-4 text-sm text-gray-600" style={{ color: "#1F1F1F", fontWeight: 600 }}>
              {holder.retailer.name}
            </div>
          </div>
        )}

        {holder && holder.retailer_id !== selectedRetailer.id && selectedRetailer.id!=-1  && (
          <div className="flex items-center pt-4">
            <div className="flex items-center justify-center rounded-full">
              <img
                className="items-center justify-center rounded-md flex overflow-hidden object-contain"
                src={imageURL[selectedRetailer.id]}
                alt="Card"
                style={{
                  height: "2.875rem",
                  width: "3.875rem",
                }}
              />
            </div>
            <div className="flex-1 pl-4 text-sm text-gray-600" style={{ color: "#1F1F1F", fontWeight: 600 }}>
              {selectedRetailer.name}
            </div>
          </div>
        )}

        {holder && selectedRetailer.id==-1 && (
            <div className="flex items-center pt-4">
              <div className="flex items-center justify-center rounded-full">
                <img
                    className="items-center justify-center rounded-md flex overflow-hidden object-contain"
                    src={imageURL[holder.retailer.id]}
                    alt="Card"
                    style={{
                      height: "2.875rem",
                      width: "3.875rem",
                    }}
                />
              </div>
              <div className="flex-1 pl-4 text-sm text-gray-600" style={{ color: "#1F1F1F", fontWeight: 600 }}>
                {holder.retailer.name}
              </div>
            </div>
        )}

        {!holder && selectedRetailer.id!=-1 &&(
          <div className="flex items-center pt-4">
            <div className="flex items-center justify-center rounded-full">
              <img
                className="items-center justify-center rounded-md flex overflow-hidden object-contain"
                src={imageURL[selectedRetailer.id]}
                alt="Card"
                style={{
                  height: "2.875rem",
                  width: "3.875rem",
                }}
              />
            </div>
            <div className="flex-1 pl-4 text-sm text-gray-600" style={{ color: "#1F1F1F", fontWeight: 600 }}>
              {selectedRetailer.name}
            </div>
          </div>
        )}

        <div className="text mt-5 mb-2 font-semibold">カード番号を入力</div>

        {/*<InputMask*/}
        {/*  className="my-5 outline-none text-sm border-b border-gray-300 pb-2"*/}
        {/*  mask="9999-9999-9999-9999"*/}
        {/*  value={cardNumber}*/}
        {/*  onChange={(e) => {*/}
        {/*    setCardNumber(e.target.value);*/}
        {/*    setOpenErrorModel(false);*/}
        {/*    setErrorMessage("");*/}
        {/*  }}*/}
        {/*  placeholder="カード番号を入力してください"*/}
        {/*></InputMask>*/}

        <input
          type="tel"
          maxLength={19}
          name="number"
          value={cardNumberValue}
          className="mt-5 outline-none text-sm border-b border-gray-300 pb-2"
          placeholder="カード番号を入力してください"
          pattern="[\d| ]{16,22}"
          required
          onChange={(event) => handleInputChange(event.target)}
          onFocus={(event) => handleInputFocus(event.target)}
        />

        {openErrorModel && (
          <div className="text-red-600 text-xs mt-2 mb-10">{errorMessage}</div>
        )}

        {!holder && (
          <div className="mt-4">
            <IonButton
              expand="block"
              color="primary"
              disabled={loading}
              onClick={() => registerCard()}
              className="w-full text-875"
            >
              保存
            </IonButton>
          </div>
        )}

        {holder && (
          <div className="mt-4">
            <IonButton
              expand="block"
              disabled={loading}
              color="primary"
              onClick={() => {
                if (cardNumber === "" || cardNumber.length === 0) {
                  setShowDelete(true);
                  setCardDeleting(true);
                } else {
                  updateCard();
                  setOpenErrorModel(false);
                  setErrorMessage("");
                }
              }}
              className="w-full text-875"
            >
              保存
            </IonButton>
          </div>
        )}

        <div className="mt-2">
          <IonButton
            expand="block"
            disabled={loading}
            color="white"
            onClick={() => {
              props.setAccountMode(false);
              history.push("tabs");
              props.setSelectedTab('home');
              setOpenErrorModel(false);
              setErrorMessage("");
            }}
            className="w-full text-875 text-gray-500 bg-white"
            style={{ borderWidth: "0.0625rem", borderColor: "#e8e8e8", borderRadius: "0.25rem" }}
          >
            あとで登録する
          </IonButton>
        </div>
        <br />
        {/*<div style={{ fontSize: "0.75em" }}>*/}
        {/*  選んだクーポンを利用するためには、対象商品ご購入の際にこのWAONを使ってお支払いください*/}
        {/*</div>*/}
        {retailer && <img src={retailer.picture_card} />}
        <br />
        {/*<div>*/}
        {/*  <div className="text font-bold">{"<"}Apple PayのWAONご利用時の注意{">"}</div>*/}
        {/*  <div style={{ fontSize: "0.75em" }}>*/}
        {/*    WAONカードをウォレットアプリにて*/}
        {/*    Apple Pay の WAONに読取設定されたお客様は*/}
        {/*    <b className="underline"> 新しいWAON番号の再登録が必要です</b>*/}
        {/*  </div>*/}
        {/*  <br />*/}
        {/*  <div className="text font-bold">{"<"}イオンカード(WAON一体型)の注意{">"}</div>*/}
        {/*  <div style={{ fontSize: "0.75em" }}>*/}
        {/*    イオンカード(WAON一体型)をご利用のお客様は*/}
        {/*    クレジットカード更新時には*/}
        {/*    <b className="underline">新しいWAON番号の再登録が必要です</b>*/}
        {/*  </div>*/}
        {/*  <br />*/}
        {/*</div>*/}
        {/*<div className="text font-bold">番号の確認方法</div>*/}

        {/*<div style={{ fontSize: "0.75em" }}>*/}
        {/*  ※「WAON番号」はカードの裏面に記載されています。*/}
        {/*</div>*/}
        <br />
        {/*<div className="block">*/}
        {/*<div className="flex">*/}
        {/*  <div className="flex-1">*/}
        {/*    <div className="text font-bold">WAON番号が2行の場合</div>*/}
        {/*    <div style={{ fontSize: "0.7em" }}>*/}
        {/*      例：WAONカード、ご当地WAONなどWAON*/}
        {/*    </div>*/}
        {/*    <div style={{ fontSize: "0.65em" }}>*/}
        {/*      番号は、1段目の左から右へ、2段目の左から右*/}
        {/*    </div>*/}
        {/*    <div style={{ fontSize: "0.7em" }}>の順番にお読みください</div>*/}
        {/*  </div>*/}
        {/*  <img className="object-contain ml-2" src={instruction1} />*/}
        {/*</div>*/}
        {/*</div>*/}
        <br />

        {/*<div className="block">*/}
        {/*<div className="flex">*/}
        {/*  <div className="flex-1">*/}
        {/*    <div className="text font-bold">WAON番号が1行の場合</div>*/}
        {/*    <div style={{ fontSize: "0.65em" }}>*/}
        {/*      例：イオンカード(WAON一体型)などのWAON*/}
        {/*    </div>*/}
        {/*    <div style={{ fontSize: "0.7em" }}>*/}
        {/*      番号は、左から右の順番にお読みください*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <img className="object-contain ml-2" src={instruction2} />*/}
        {/*</div>*/}
        {/*</div>*/}


      </div>
      {/* {openErrorModel && (
        <InformModal
          message={errorMessage}
          open={openErrorModel}
          setOpen={setOpenErrorModel}
        />
      )} */}
    </ScrollpageWithHeader>
  );
};

export const Screen9 = connect(
  mapStateToProps,
  mapDispatchToProps
)(Screen9Component);
